import jQuery from 'jquery';

//--------------------------------------------------
// Accordion
jQuery(function($) {

    if($('.accordion').length) {

        $('.accordion').each(function(){
            var item = $(this);
            var question = $('.item-title', item);
            var answer = $('.item-content', item);

            question.attr('aria-expanded', 'false');
            answer.attr('hidden', true);

            question.on('click',function(e){
                e.preventDefault();
                var currentQ = $(this);

                //--------------------------------------------------
                // Hide/Show - allow current FAQ to be hidden too

                    if (!currentQ.hasClass('current')) {
                        question.addClass('current').attr('aria-expanded', 'true')
                        answer.attr("hidden",false);
                    } else {
                        question.removeClass('current').attr('aria-expanded', 'false')
                        answer.attr("hidden",true);
                    }

            });


        }); //.accordion each

    } //if .accordion

});
